import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import OccupiedStagging from '../component/OccupiedStagging/OccupiedStagging'
import { Helmet } from 'react-helmet'

function Design1() {
    return (
        <>
          {/* <Helmet>
            <title> 
            Professional Commercial, Residential Interior Decorating Services Canada 
            </title>
            <meta 
            name='description' 
            content="Transform your business environment with MES Décors' expert commercial residential interior decorating services in Canada. Boost productivity, customer satisfaction, and revenue." 
            />
<link rel="canonical" href="https://www.mesdecors.ca/commercial" />

            </Helmet> */}
        
            <HeroBanner 
                subHeading=""
                Heading="Residential"
                nextLineSpanHeading=""
                Paragraph=""
                bgImg="/images/creatives/design-residential-hero-bg.png"
                btn={false}
                sideLinks={false}
            />
    
    <TextContent
      subHeading=""
      heading="Home Design – Crafted for Elegance & Comfort"
      content={`At MES Décors, we believe that home design is more than just aesthetics, it’s about creating a space that reflects your personality, enhances functionality, and brings comfort to your everyday life. Our Interior Design Studio specializes in transforming homes into stunning, well-curated spaces tailored to your lifestyle and vision.`}
      btn={true}
      btnText="Get A QUOTE"
      btnRef='/get-a-quote'
      btnRefTel={false}
      shiftRow={false}
      img="/images/creatives/dr-text-1.jpg"
      reviewContent={false}
      btnImg={false}
      fontDark={true}
  />

    <OccupiedStagging
        heading="Bespoke Interiors, Thoughtfully Designed"
        content="Whether you’re refreshing a single room or reimagining your entire home, our expert designers will guide you through every step of the process. We carefully balance color, texture, and space planning to create interiors that feel both timeless and uniquely yours."
        card1={{
            title:"Design Concept & Space Planning",
            content:"Our journey begins with a one-on-one consultation to understand your vision, goals, and lifestyle. By carefully listening to your needs, we create a structured plan that serves as the foundation for your project. Every layout is meticulously designed to optimize both functionality and aesthetic appeal, ensuring a space that is as practical as it is beautiful."
        }}
        card2={{
            title:"Materials & Finishes Selection",
            content:"Once the design concept, floor plan, and furniture layout are approved, we curate a selection of high-quality furniture, textiles, and accessories to bring harmony to your space. A mood board is then presented for your approval, ensuring that every detail aligns with your style and vision."
        }}
        card3={{
            title:"Elevations, 3D Renderings & Final Design Package",
            content:"With the mood board and color palette finalized, we move on to creating detailed elevations and 3D renderings, giving you a clear visualization of how your space will come to life. \n Disclaimer: Rendered visuals are intended to provide the best possible representation of the final space. While we strive for accuracy, material selections may be subject to change based on availability and site conditions. This process does not include any construction, engineering drawings or any building or municipal permits."
        }}
      
    />

        {/* <OccupiedStagging
            heading="Lorem Ipsum Dolor Sit Amet"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Phasellus efficitur, lectus eget auctor volutpat, ligula est consequat arcu, non gravida erat arcu nec velit."
            card1={{
                title:"Lorem Ipsum",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vestibulum, nisi eget molestie convallis, urna justo bibendum orci, eget aliquet ligula risus id lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla purus vel velit facilisis, at interdum mauris auctor. Curabitur ac lorem risus. Vestibulum euismod turpis eget libero fermentum, at aliquam purus efficitur."
            }}
            card2={{
                title:"Lorem Ipsum",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vestibulum, nisi eget molestie convallis, urna justo bibendum orci, eget aliquet ligula risus id lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla purus vel velit facilisis, at interdum mauris auctor. Curabitur ac lorem risus. Vestibulum euismod turpis eget libero fermentum, at aliquam purus efficitur."
            }}
            card3={{
                title:"Lorem Ipsum",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vestibulum, nisi eget molestie convallis, urna justo bibendum orci, eget aliquet ligula risus id lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla purus vel velit facilisis, at interdum mauris auctor. Curabitur ac lorem risus. Vestibulum euismod turpis eget libero fermentum, at aliquam purus efficitur."
            }}
            
            lightTheme={true}
        /> */}

            {/* <TextContent
                subHeading=""
                heading= "THE ART OF STAGING"
                content={`Vacant homes can often feel cold and uninviting, making it hard for potential buyers to envision their lives there. At MES Décors, we specialize in the art of vacant staging, transforming empty spaces into warm, welcoming environments.\n Our team carefully selects and arranges furniture, artwork, and accessories to highlight each room's potential and charm. By creating a lived-in feel, we help buyers connect emotionally with the space, increasing the likelihood of a quick and profitable sale. Let us turn your vacant property into a buyer's dream home.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/home-owner-text-2.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            /> */}
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Design1