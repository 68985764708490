import { useState } from "react";
import "./Footer.css"
import { Link,useNavigate,NavLink } from "react-router-dom";
import axios from "axios"
import {Spinner } from "react-bootstrap"


function Footer({
    newsletterSection=true
}) {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
  
    const formSubmitHandler = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post("/send-newsletter", {
          email: email,
          formType: "Newsletter",
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setError(false);
            setSuccess("Newsletter Subscribed Successfully!");
            setLoading(false);
            setEmail("");
          } else if (response.data.msg === "fail") {
            setSuccess(false);
            setError("Email failed to send, try again by reloading the page.");
            setLoading(false);
            setEmail("");
          }
          })
        }
  return (
    <>
    
    <div className={`footer-container `}>
        <div className="container px-0">
        <div className="form container">
        {newsletterSection && 
            <div className="container-fluid">
                <div className="form-container row gy-5">
                    <div className="col-lg-6">
                        <div 
                            className="text-container text-center d-flex flex-column flex-lg-row align-items-center gap-3"

                        >
                            <div 
                                className="mail-img-container d-flex align-items-center justify-content-center  my-3 "
                            >
                                 <img src="/images/icons/footer-mail-icon.png" className="img-fluid mail-img" alt="icon" loading='lazy'/>
                            </div>
                            <h3 className="body-heading color-light text-center text-lg-start" style={{textTransform:"uppercase"}}>
                            Subscribe to Our Newsletter
                            </h3>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                        <form className='inp-container d-flex align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                            <input 
                                type='text' 
                                placeholder='Enter your Email...'  
                                value={email}
                                maxLength="36"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className='inp-btn d-flex align-items-center gap-2 '>
                                <span>SUBSCRIBE</span>
                                <img src="/images/icons/btn-bell.svg" className="bell-icon mb-0" alt="icon" loading='lazy'/>
                                {loading ? (
                                    <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                                    />
                                ) : (
                                    success ? (
                                    <div >
                                        {setLoading(false)}
                                        {alert(success)}
                                        {setSuccess(false)}
                                    </div>
                                    ) : (
                                    error && (
                                        <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                        </div>
                                    )
                                    )
                                )}
                            </button>
                        </form>
                    </div>
                
                
                </div>
            </div>
        }
        </div>
        {/* newsletter Upper section */}
        <div className="line-seprator"></div>
        <div className="upper-newsletter-container container-fluid mx-auto mt-5 pt-4" >

            <div className="row d-flex align-items-start gy-5">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="text-container">
                        <div className="newsletter-logo img-fluid mb-3">
                            {/* <img  src="/images/icons/footer-nav-logo.png" alt="brand icon" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}} loading='lazy'/> */}
                            <img  src="/images/icons/footer-new-logo.png" alt="brand icon" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer",maxWidth:"190px"}} loading='lazy'/>
                        </div>
                        <div className="text-content">
                            <p className="body-paragraph my-3" style={{color:"white"}}>Transforming spaces, one home at a time. MES Décors brings beauty and functionality to every project, making your property shine. Discover the difference with us.</p>
                        </div>
                        
                    
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="page-links d-flex flex-md-row gap-3 flex-column align-items-md-start align-items-center text-center justify-content-md-evenly justify-content-center">
                        <div className="text-md-start text-center d-flex flex-column gap-3">
                            <h3 className="footer-title color-light font-mons">Quick Links</h3>
                            <NavLink to={"/"} className="anchor" exact activeClassName="active">
                                HOME
                            </NavLink>
                            <NavLink to={"/portfolio"} className="anchor" exact activeClassName="active">
                            PORTFOLIO
                            </NavLink>
                            <NavLink to={"/about"} className="anchor" exact activeClassName="active">
                            ABOUT
                            </NavLink>
                            <NavLink to={"/get-a-quote"} className="anchor" exact activeClassName="active">
                            REQUEST A QUOTE
                            </NavLink> 
                            <NavLink to={"/blog"} className="anchor" exact activeClassName="active">
                            BLOGS
                            </NavLink> 
                            <NavLink to={"/contact"} className="anchor" exact activeClassName="active">
                            CONTACT US  
                            </NavLink> 
                           
                        </div>
                        <div className="text-md-start text-center d-flex flex-column  gap-3">
                            <h3 className="footer-title color-light font-mons mt-4 mt-lg-0">Consultation</h3>
                            <NavLink to={"/staging"} className="anchor" exact activeClassName="active">
                            Staging
                            </NavLink> 
                            <NavLink to={"/decorating"} className="anchor" exact activeClassName="active">
                            DECORATING
                            </NavLink> 
                            <NavLink to={"/staging-builders"} className="anchor" exact activeClassName="active">
                            BUILDERS
                            </NavLink> 
                            <NavLink to={"/design"} className="anchor" exact activeClassName="active">
                            Design
                            </NavLink> 
                            <NavLink to={"/staging-realtors"} className="anchor" exact activeClassName="active">
                            REALTORS
                            </NavLink> 
                            <NavLink to={"/decorating-organizing"} className="anchor" exact activeClassName="active">
                            ORGANIZING
                            </NavLink> 
                            <NavLink to={"/decorating-organizing"} className="anchor" exact activeClassName="active">
                            DECLUTTER
                            </NavLink> 
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-12">
                    <div className="info-container">
                    <h3 className="footer-title color-light font-mons mb-5 text-center text-lg-start">Get in Touch</h3>
                       <div className="d-flex flex-column align-items-center justify-content-start gap-4 ">
                            <div className="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 w-100">
                                <img src="/images/icons/phone-location-icon.svg" alt="icon" loading='lazy'/>
                                <div>
                                    <a href="tel:(437) 998-6650" className="body-heading info-content" style={{color:"white",textDecoration:"none",cursor:"pointer"}}>(437) 998-6650</a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-lg-start justify-content-center gap-2  w-100">
                                <img src="/images/icons/mail-location-icon.svg" alt="icon" loading='lazy'/>
                                <div>
                                <a href="mailto:inquiry@mesdecors.ca" className="body-heading info-content" style={{color:"white",textDecoration:"none",textTransform:"lowercase",cursor:"pointer"}}>inquiry@mesdecors.ca</a>
                                    
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 w-100">
                                <img src="/images/icons/footer-location-icon.svg" alt="icon" loading='lazy'/>
                                <div>
                                    
                                    <p className="body-heading info-content mb-0" style={{textTransform:"capitalize"}}>Milton, Ontario, Canada</p>
                                </div>
                            </div>
                           
                       </div>
                    </div>
                </div>

            </div>
            
           
        </div>
        </div>
        {/* div for border lining */}
        <div className="my-5"></div>

        {/* Lower sections */}

        <div className="lower-section text-center py-1">
            <div className="lower-container container flex-column gap-1 flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
            <p className="mb-0">© 2024 | MES Décors | All Rights Reserved</p>
            <div className="social-link d-flex align-items-center justify-content-center gap-3 mt-2">
                <a href="https://www.tiktok.com/@mes.decors " target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/tiktok-icon.png" alt="icon" className="social-icons" loading='lazy'/>
                </a>
                <a href="https://www.instagram.com/mesdecors.homestaging/ " target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/insta-icon.png" alt="icon" className="social-icons" loading='lazy'/>
                </a>
                <a href="https://www.facebook.com/mesdecors.homestaging" target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/fb-icon.png" alt="icon" className="social-icons" loading='lazy'/>
                </a>
                <a href="https://www.linkedin.com/company/mes-d-cors-inc/ " target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/li-icon.png" alt="icon" className="social-icons" loading='lazy'/>
                </a>
                



            </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer