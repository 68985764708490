import React, { useState } from 'react'
import "./PortfolioSection.css"
import AppointmentModal from '../AppointmentModal/AppointmentModal'
import PortfolioModal from '../PortfolioModal/PortfolioModal'
import Modal from '../Modal/Modal'

const catagory = [
    {
        id:0,
        name:"Residential Projects",
    },
    {
        id:1,
        name:"Commercial Projects",
    },
    {
        id:2,
        name:"Global Projects",
    },
]

const portfolioData = [
    {
        id:0,
        imgIni:[
             "/images/portfolio/residential-projects/portfolio-img-1.jpg",
            "/images/portfolio/residential-projects/portfolio-img-2.jpg",
            ],
        img:[
            // 2024
            "/images/portfolio/residential-projects/2024/1.jpg",
            "/images/portfolio/residential-projects/2024/2.jpg",
            "/images/portfolio/residential-projects/2024/3.jpg",
            "/images/portfolio/residential-projects/2024/4.jpg",
            "/images/portfolio/residential-projects/2024/5.jpg",
            "/images/portfolio/residential-projects/2024/6.jpg",
            "/images/portfolio/residential-projects/2024/7.jpg",
            "/images/portfolio/residential-projects/2024/8.jpg",
            "/images/portfolio/residential-projects/2024/9.jpg",
            "/images/portfolio/residential-projects/2024/10.jpg",
            "/images/portfolio/residential-projects/2024/11.jpg",
            "/images/portfolio/residential-projects/2024/12.jpg",
            "/images/portfolio/residential-projects/2024/13.jpg",
            "/images/portfolio/residential-projects/2024/14.jpeg",
            "/images/portfolio/residential-projects/2024/15.jpeg",
            "/images/portfolio/residential-projects/2024/16.jpeg",
            "/images/portfolio/residential-projects/2024/17.jpeg",

            // old
           "/images/portfolio/residential-projects/portfolio-img-3.jpg",
            "/images/portfolio/residential-projects/portfolio-img-4.png",
            "/images/portfolio/residential-projects/portfolio-img-5.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-6.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-7.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-8.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-9.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-10.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-11.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-12.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-13.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-14.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-15.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-16.jpeg",
            "/images/portfolio/residential-projects/portfolio-img-17.jpg",
            "/images/portfolio/residential-projects/portfolio-img-18.jpg",
            "/images/portfolio/residential-projects/portfolio-img-19.jpg",
            "/images/portfolio/residential-projects/portfolio-img-20.jpg",
            "/images/portfolio/residential-projects/portfolio-img-21.jpg",
            "/images/portfolio/residential-projects/portfolio-img-22.jpg",
            "/images/portfolio/residential-projects/portfolio-img-23.jpg",
            "/images/portfolio/residential-projects/portfolio-img-24.jpg",
        ]
    },
    {
        id:1,
        imgIni:[
            "/images/portfolio/commercial-projects/portfolio-img-1.jpg",
           ],
       img:[
       ]
    },
    {
        id:2,
        imgIni:[
            "/images/portfolio/global-projects/portfolio-img-1.jpg",
           "/images/portfolio/global-projects/portfolio-img-2.jpg",
           ],
       img:[
          "/images/portfolio/global-projects/portfolio-img-3.jpg",
           "/images/portfolio/global-projects/portfolio-img-4.jpg",
           "/images/portfolio/global-projects/portfolio-img-5.jpg",
           "/images/portfolio/global-projects/portfolio-img-6.jpg",
           "/images/portfolio/global-projects/portfolio-img-7.jpg",
           "/images/portfolio/global-projects/portfolio-img-8.jpg",
           "/images/portfolio/global-projects/portfolio-img-9.jpg",
           "/images/portfolio/global-projects/portfolio-img-10.mp4",
       ]
    },
]

function PortfolioSection() {
    const [selectedId , setSelectedId] = useState(0)
    const [modalOpen, setModalOpen] = useState(false);
    const [src, setSrc] = useState("");

    function generateSequence(limit) {
        const sequence = [];
        let index = 0;
        while (index < limit) {
          sequence.push(index);
          index += 3;
          if (index < limit) sequence.push(index);
          index += 1;
        }
        return sequence;
      }
      const sequence = generateSequence(portfolioData[0].img.length);

      const closeModal = () => {
        setModalOpen(false);
      }
    
  return (
    <div className='PortfolioSection-container '>
            <div className='container '>
                <div className='btn-container'>
                    <div className='row gy-4 align-items-center bg-white'>
                        {
                            catagory.map((ele)=>{
                                return(
                                    <div className='col-sm-4 text-center'>
                                        <button
                                            className={`${selectedId == ele.id ? "active-btn" :"genral-btn-light"} `}
                                            onClick={()=>{setSelectedId(ele.id)}}
                                        >
                                            {ele.name}
                                        </button>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>

                <div className='portfolio-container mt-5'>
                    <div className='row gy-5 align-items-end'>
                        {
                            portfolioData[selectedId].imgIni.map((pd,ind)=>{
                                return(
                                    <div className={`col-lg-6 px-3`}>
                                        <div 
                                            className={`i${ind}-box box`}
                                            style={{backgroundImage:`url("${pd}")`}}
                                            onClick={()=>{setModalOpen(true);setSrc(pd)}}
                                        >
            
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            portfolioData[selectedId].img.map((pd,ind)=>{
                                if(pd.endsWith('.mp4'))
                                {
                                    return(
                                        <div className={`col-lg-${sequence.includes(ind) ? "7" : "5"} px-3`}>
                                            <video controls className=" w-100" style={{height:"400px"}}>
                                                <source src={pd} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    )

                                }
                                else{
                                    
                                return(
                                    <div className={`col-lg-${sequence.includes(ind) ? "7" : "5"} px-3`}>
                                        <div 
                                            className={`b${ind}-box box`}
                                            style={{backgroundImage:`url("${pd}")`}}
                                            onClick={()=>{setModalOpen(true);setSrc(pd)}}
                                        >
            
                                        </div>
                                    </div>
                                )
                                
                            }
                            })
                        }
                        {modalOpen && <AppointmentModal setOpenModal={setModalOpen} src={src} />}
                        

                    </div>

                </div>
            </div>
    </div>
  )
}

export default PortfolioSection