import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import Contactform from '../component/Contact Form/Contactform'
import DecoratingInteriorDesign from '../component/DecoratingInteriorDesign/DecoratingInteriorDesign'
import { Helmet } from 'react-helmet'
import DesignInerior from '../component/DesignInerior/DesignInerior'

function Design() {
    return (
        <>
                      
            {/* <Helmet>
                <title> 
                Expert Decorating Services | Interior Design Service Consultation Canada
                </title>
                <meta 
                name='description' 
                content="Discover MES Décors' expert decorating services for homes and businesses. Enhance your space with personalized, stylish, and creative interior design solutions and Consultation in Canada" 
                />
<link rel="canonical" href="https://www.mesdecors.ca/decorating" />
                </Helmet> */}
        
            <HeroBanner 
                subHeading=""
                Heading="Design"
                nextLineSpanHeading=""
                Paragraph=""
                bgImg="/images/creatives/design-hero-bg.png"
                btn={false}
                sideLinks={false}
            />
    
          <DesignInerior
            heading="A BROAD SET OF INTERIOR DESIGN"
            content="At MES Décors, we believe that every space tells a story. As a full-service Interior Design Studio, we specialize in interior bespoke design solutions that transform ordinary spaces into extraordinary experiences. Whether you're looking to refresh your home, stage a property for sale, or enhance your commercial space, our expert team is here to bring your vision to life."
            

          />
           
          <Contactform/>
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Design