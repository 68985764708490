import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import OccupiedStagging from '../component/OccupiedStagging/OccupiedStagging'
import { Helmet } from 'react-helmet'

function Design2() {
    return (
        <>
          {/* <Helmet>
            <title> 
            Professional Commercial, Residential Interior Decorating Services Canada 
            </title>
            <meta 
            name='description' 
            content="Transform your business environment with MES Décors' expert commercial residential interior decorating services in Canada. Boost productivity, customer satisfaction, and revenue." 
            />
<link rel="canonical" href="https://www.mesdecors.ca/commercial" />

            </Helmet> */}
        
            <HeroBanner 
                subHeading=""
                Heading="Commercial"
                nextLineSpanHeading=""
                Paragraph=""
                bgImg="/images/creatives/design-commercial-hero-bg.png"
                btn={false}
                sideLinks={false}
            />
    
    <TextContent
      subHeading=""
      heading="Designing for Profitability & Customer Experience"
      content={`At MES Décors, we design a strategic tool for business success. Every space we create is thoughtfully designed to enhance the customer experience, drive engagement, and ultimately increase revenue. Whether it’s a retail store, restaurant, office, or hospitality venue, our designs are tailored to optimize flow, functionality, and ambiance, encouraging longer visits, repeat customers, and higher sales. By blending brand storytelling, strategic layouts, and sensory elements, we craft spaces that not only look stunning but also work effectively to support your business goals.`}
      btn={true}
      btnText="Get A QUOTE"
      btnRef='/get-a-quote'
      btnRefTel={false}
      shiftRow={false}
      img="/images/creatives/dc-text-1.png"
      reviewContent={false}
      btnImg={false}
      fontDark={true}
  />

    <OccupiedStagging
        heading="Our Process"
        content=""
        card1={{
            title:"Design Concept, Space Planning & Branding Integration",
            content:"We begin with an in-depth consultation to understand your brand identity, business objectives, and operational needs. Our design strategy focuses on optimizing space for efficiency, functionality, and customer engagement while seamlessly integrating your brand’s essence. Through thoughtful use of color psychology, signage, lighting, and custom branding elements, we create an environment that enhances the customer experience and reinforces brand recognition. Each layout is carefully crafted to maximize space utilization, improve workflow, and create a lasting impression on clients and employees alike."
        }}
        card2={{
            title:"Materials, Finishes & Sustainable Solutions",
            content:"Once the design concept, floor plan, and layout are approved, we curate a selection of commercial-grade, durable, and sustainable materials that align with your business needs. Whether it’s furniture, wall treatments, lighting, or textiles, we prioritize eco-friendly, high-quality, and low-maintenance solutions that balance aesthetics with longevity. A mood board is presented for approval, ensuring consistency in branding, durability, and functionality for high-traffic environments."
        }}
        card3={{
            title:"Technology Integration & Smart Solutions",
            content:"To create modern, future-ready spaces, we incorporate innovative technology solutions that enhance both functionality and user experience. From smart lighting and automated climate control to interactive displays and soundscaping, we ensure your commercial space is both efficient and engaging. Our approach helps businesses improve energy efficiency, streamline operations, and provide a seamless experience for employees and customers."
        }}
        card4={{
            title:"Elevations, 3D Renderings & Final Design Package",
            content:"With the design direction finalized, we create detailed elevations and photorealistic 3D renderings, allowing you to visualize the transformation before implementation. These visuals help refine design elements, ensure alignment with your brand vision, and facilitate decision-making. \n Disclaimer: Rendered visuals are intended to provide the best possible representation of the final space. While we strive for accuracy, material selections may be subject to change based on availability and site conditions. This process does not include any construction, engineering drawings or any building or municipal permits."
        }}
      
    />

        {/* <OccupiedStagging
            heading="Lorem Ipsum Dolor Sit Amet"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Phasellus efficitur, lectus eget auctor volutpat, ligula est consequat arcu, non gravida erat arcu nec velit."
            card1={{
                title:"Lorem Ipsum",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vestibulum, nisi eget molestie convallis, urna justo bibendum orci, eget aliquet ligula risus id lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla purus vel velit facilisis, at interdum mauris auctor. Curabitur ac lorem risus. Vestibulum euismod turpis eget libero fermentum, at aliquam purus efficitur."
            }}
            card2={{
                title:"Lorem Ipsum",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vestibulum, nisi eget molestie convallis, urna justo bibendum orci, eget aliquet ligula risus id lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla purus vel velit facilisis, at interdum mauris auctor. Curabitur ac lorem risus. Vestibulum euismod turpis eget libero fermentum, at aliquam purus efficitur."
            }}
            card3={{
                title:"Lorem Ipsum",
                content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vestibulum, nisi eget molestie convallis, urna justo bibendum orci, eget aliquet ligula risus id lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla purus vel velit facilisis, at interdum mauris auctor. Curabitur ac lorem risus. Vestibulum euismod turpis eget libero fermentum, at aliquam purus efficitur."
            }}
            
            lightTheme={true}
        /> */}

            {/* <TextContent
                subHeading=""
                heading= "THE ART OF STAGING"
                content={`Vacant homes can often feel cold and uninviting, making it hard for potential buyers to envision their lives there. At MES Décors, we specialize in the art of vacant staging, transforming empty spaces into warm, welcoming environments.\n Our team carefully selects and arranges furniture, artwork, and accessories to highlight each room's potential and charm. By creating a lived-in feel, we help buyers connect emotionally with the space, increasing the likelihood of a quick and profitable sale. Let us turn your vacant property into a buyer's dream home.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/home-owner-text-2.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            /> */}
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Design2