import "./Testimonials.css"
import React,{useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Pagination  } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import TestimonialCard from "./TestimonialCard"

const testimonialData = [
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Arturo Commisso",
    rank:"Local Guide",
    clientReview:"Louay and Mirna are magicians. I hired them to do a redesign of my photography studio because, while spacious, was starting to feel cluttered and chaotic. I was looking to do something that was inviting and focused on my clients experience , but still functional for me to be creative with lots of storage and open space for equipment in use.\n They listened to exactly what I needed and took the time to learn how I use the space. This was so valuable to me because the layout needed to fit my workflow - not the other way around.\n They definitely delivered.\n The thing is, sometimes when you are attached to a space, it’s hard to visualize new possibilities for it. Design isn’t just aesthetics. It’s functionality. And the designs I got from these guys didn’t just meet my expectations, but exceeded them 10 fold.\n I would highly recommend these guys if you’re looking for expert, thoughtful and professional work.\n I’m so excited to see these designs come to life!!!! Thanks guys!!!"
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Yousuf Khaja",
    rank:"",
    clientReview:"Working with Louay and Mirna was an absolute delight! They possess an incredible eye for design and attention to detail that transformed our home into a stunning, cohesive space. From the initial consultation to the final staging, their professionalism shone through. Every room now reflects a perfect blend of functionality and style, capturing our vision beautifully. If you're looking to elevate your home's aesthetic, I wholeheartedly recommend Louay and Mirna!"
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"rental homes",
    rank:"",
    clientReview:"I had a fantastic experience with Louay Nouneh from MES Decors! After his magic touch, my office space looks exceptionally professional and inviting. Louay’s decorating ideas and visually appealing design options truly stand out. What impressed me most was the team’s attention to detail and patience—they ensured every element was perfect and didn’t stop until everything matched my vision. Their professionalism and reliability made the entire process stress-free. If you’re seeking decorators who care about getting it right and delivering top-quality results, MES Decors is the way to go! Highly recommend!"
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Andrew Tadros",
    rank:"",
    clientReview:"I’m thrilled to have recently secured a Co-op position with MES Décors, a fantastic Design, Staging, and Décor company located in Milton. From the very first interaction, the team at MES Décors has been incredibly supportive and professional, making me feel like a valuable part of the company.\n In my role as Information Service Desk and Customer Support, I’ll be maintaining and utilizing software to generate both regular and advanced reports for senior management. This hands-on experience will allow me to provide technical support across various hardware and software, collaborate with stakeholders, and play a role in enhancing operational efficiency by analyzing reports and suggesting improvements.\n MES Décors’ commitment to creativity, attention to detail, and innovative solutions make it a fantastic environment to grow professionally. I am genuinely excited to contribute to such a dynamic company and be part of their continued success!"
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Chris K. Cestar",
    rank:"",
    clientReview:"Working alongside Mirna and Louay from Mes Décors to prepare a client's house for the market was a fantastic experience. Their staging brought the space to life, making it more appealing to potential buyers. Their keen sense of style and attention to detail complemented the work perfectly. I highly recommend them for anyone looking to showcase their property at its best!"
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Adrian Ortisi",
    rank:"",
    clientReview:"I've recently begun reffering my customers to Louay from MES Decors for their staging and design needs and I must say, they do amazing work! I've gotten nothing but positive feedback from my customers. I highly recommend them and would not hesitate to recommend them in the future!"
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Michael Baznick",
    rank:"",
    clientReview:"Louay Nouneh and the team at MES Decors is a group of true professionals. They are not only highly skilled in interior design and home staging but also incredibly passionate about what they do. Their dedication to making every space they touch absolutely shine is evident in the results they deliver.\n They are experts in bringing out the true beauty of your home and offer valuable tips on how to maintain the look they create and even provide suggestions for future design upgrades.  If you're looking for a sophisticated upgrade to your home, look no further.\n I recommend Louay to all my friends and family!"
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Vanessa Vargas",
    rank:"",
    clientReview:"Louay is an amazing person to work with. His professionalism and dedication to his clients are his top priorities, and this is evident in every aspect of his work. From our initial conversation, it was clear that Louay possesses extensive knowledge and expertise in his field. He has an incredible ability to understand and bring your vision to life, whether it's interior design or staging.\n Louay's years of experience shine through in every project he undertakes. He and his wife approach each job with unparalleled passion and commitment, transforming spaces into beautiful and functional environments. Their meticulous attention to detail and innovative design solutions ensure that every project is completed to the highest standards.\n Working with Louay is a truly enjoyable experience. He listens carefully to your needs and preferences, offering insightful suggestions and creative ideas to enhance your vision. His dedication to making sure you are satisfied with the final result is truly remarkable.\n I highly recommend Louay for your next project. His expertise, passion, and professionalism set him apart from others in the industry. You won't be disappointed with the exceptional quality and care he brings to his work."
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Liberty Tax",
    rank:"",
    clientReview:"Louey and Mirna have great eyes and taste for details to bring out the hidden beauty in your home I would certainly recommend them for design staging and decor."
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Joshua Knowles",
    rank:"",
    clientReview:"Consummate professionals. Whether it is staging your home to sell, redecorating and remodeling advice and assistance, or decluttering your home, Louay and his team have you covered. Kind, thoughtful, and thorough, they make the process of selling your home easier and much more profitable. Highly recommend, this is an investment in your future!"
  },

  // =========old reviews 20-2-25
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"John DeSouza",
    rank:"Real Estate Photographer",
    clientReview:"In the world of real estate selling, presentation is paramount, and when it comes to apartments, the art of home staging can make all the difference in attracting potential buyers or renters. Recently, I as a real estate photographer, had the pleasure of experiencing firsthand the transformative effects of professional home staging services in an apartment, and I must say, the results were nothing short of impressive. This made my life very easy to capture the essence of the apartment. The staging gave life to the rooms, else I would have been just taking photos of the empty rooms with barren walls. The attention to detail was evident from the moment I stepped in. Through strategic placement and thoughtful design choices, the staging team was able to create a sense of flow and balance that truly elevated the space in this small apartment. The result was a space that felt not only visually appealing but also lived-in and inviting – a place where prospective buyers could envision themselves making a home. In conclusion, after having photographed apartments and houses for so many years, I can truly recommend this home staging service. The expertise and attention to detail provided by the staging team were truly exceptional, and the impact on the apartment's marketability was nothing short of remarkable."
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Vanessa Pereira",
    rank:"Realtor",
    clientReview:"I recently worked with Mirna and Louay from MES Décors to stage two properties. They are just amazing with their ideas and are very detail oriented. They go above and beyond. Mirna with her interior design background and Louay taking care of the smallest details, together make an awesome team. The sellers were thrilled to see their homes when they were listed. I highly recommend their services and I can assure you that you will be presently surprised."
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Matthew",
    rank:"Events Photographer",
    clientReview:'If you more value and a greater selling price for your home look no further! MES Decor is professional and knowledgeable  and brings style to your homes staging. Give  it that "Wow" factor.  Let them help you attract the perfect buyer and the sale and price that you want to your home.  From start to finish their team will consult and take care of everything. Highly recommended!'
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Eliie Maidaa",
    rank:"",
    clientReview:'I have worked with Louay on decorating my town house in Whitby. My wife and I were very satified with their service and attention to details. Strongly recomended'
  },

] 

function Testimonials() {



  return (
    <div className="Testimonials-container py-5">
      <div className="container w-100 container-fluid py-4">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <p className="sub-heading mb-2 color-dark" >Testimonials </p>
        <h3 className="body-heading color-dark mt-4">WHAT OUR VALUABLE CLIENTS SAY</h3>
        

        <div className="card-list" style={{position:"relative"}}>

        <Swiper
      spaceBetween={0}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination]}
      pagination={{ clickable: true }}
      observer={true}
      observeParents={true}
      parallax={true}
      breakpoints={{
        280: { slidesPerView: 1 },
        1450: { slidesPerView: 3 },
      }}
      className="testimonials-list py-5 mt-2"
      id="testimonial-cards-slider"
      onSwiper={(swiper) => (window.swiperInstance = swiper)} 
    >
      {testimonialData.map((clientData, ind) => (
        <SwiperSlide
          key={ind}
          className="pt-5 px-3 px-sm-0"
          onMouseEnter={() => window.swiperInstance?.autoplay.stop()}
          onMouseLeave={() => window.swiperInstance?.autoplay.start()}
        >
          <TestimonialCard
            clientImg={clientData.clientImg}
            clientName={clientData.clientName}
            clientReview={clientData.clientReview}
          />
        </SwiperSlide>
      ))}
    </Swiper>
        
        </div>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials