import React from 'react'
import "./Sponsor.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Sponsor({
  textSection=true
}) {
  return (
    <div className='sponsor-container  standard-margin-space py-1'>
      <div className='container'>

        {
          textSection &&
          <>
            <div className={`text-content text-center`}>
              {/* <p className='sub-heading mb-0 justify-content-center d-flex aling-items-center gap-lg-4 gap-3'><div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div>OUR partners<div style={{height:"0",width:"50px",border:"1px solid #A1845D",margin:"auto 0" }}></div></p> */}

                <h3 className='body-heading my-2 mt-3 color-dark '>MES Décors CERTIFICATIONS AND AFFILIATIONS:</h3>
                <p className='body-paragraph my-3 color-dark w-75 mx-auto'>Our dedication to excellence has earned us recognition in the home staging industry. MES Décors is proud to be affiliated with esteemed organizations, reflecting our commitment to quality and professionalism.</p>
              
            </div>
              
            <div className='py-2 mt-4'></div>
          </>
        }
        <div className="companies-logo-box ">
        <Swiper
            spaceBetween={50}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
              }}
            className="mx-auto"
            >
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-1.png" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-2.png" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-3.png" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-4.webp" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-5.png" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-6.jpg" alt="partner icon" />
                </SwiperSlide>

                
                {/* <SwiperSlide>
                <img src="/images/icons/sponsor-logo-7.jpg" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-8.jpg" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-9.jpg" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-10.png" alt="partner icon" />
                </SwiperSlide> */}
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-11.png" alt="partner icon" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="/images/icons/sponsor-logo-12.png" alt="partner icon" />
                </SwiperSlide>

                
                
                
                  
            </Swiper>
        </div>
        <div className='py-2'></div>
        </div>
    </div>
  )
}

export default Sponsor