import React from 'react'
import "./DesignInerior.css"
import { useNavigate } from 'react-router-dom'

function ImgCard({
    title="",
    content="",
    img="",
    width="50",
    anchor="/"
})
{
    const navigate = useNavigate()
    return(
        <div 
        className='img-container w-100 d-flex flex-column align-items-start justify-content-end p-xl-5 p-4'
        style={{backgroundImage:`url(${img})`}}
        onClick={()=>{navigate(anchor)}}
    >
        <h3 className='body-heading color-light' style={{textTransform:"uppercase"}}>{title}</h3>
        <p className={`body-paragraph color-light w-${width}`}>{content}</p>
    </div>
    )
}

function DesignInerior({
    heading="",
    content=""
}) {
  return (
    <div className='DesignInteriorDesign-container standard-padding-space standard-margin-space'>
        <div className='container'>
            <div className='text-sec'>

                <div className='row'>
                    <div className='heading-sec col-lg-6'>
                        <h3 className='body-heading color-dark'>{heading}</h3>
                    </div>
                    <div className='heading-sec col-lg-6'>
                        <p className='body-paragraph color-dark'>{content}</p>
                    </div>

                </div>
                

            </div>
            <div className='img-sec my-4'>
                
                    <div className='mt-4'>
                    <ImgCard
                        title="Residential"
                        content=""
                        img='/images/creatives/design-residential-hero-bg.png'
                        width='50'
                        anchor='/design/residential'
                    />
                    </div>
                    <div className='mt-4'>
                        <ImgCard
                            title="Commercial"
                            content=''
                            img='/images/creatives/design-commercial-hero-bg.png'
                            width='50'
                            anchor='/design/commercial'
                        />
                    </div>

                    {/* <div className='mt-4'>
                        <ImgCard
                            title="Design 3"
                            content=''
                            img='/images/creatives/did-card-bg-3.jpg'
                            width='50'
                            anchor='/design-2'
                        />
                    </div> */}
            </div>             
        </div>

    </div>
  )
}

export default DesignInerior